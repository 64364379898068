import React from 'react';
import {Alert, Button, Grid, Typography} from '@mui/material';
import {Link, useSearchParams, useParams} from 'react-router-dom';
import {useQuery, useMutation} from 'react-query';
import PropTypes from 'prop-types';

import {getMatchJobStatus, updateMatchJobStatus} from 'src/api';
import TopCards from './TopCards';
import {statusMap} from 'src/utilities/statusMap';
import Breadcrumb from 'src/components/Breadcrumb';
import MatchFileDeliveryMessage from './matchFileDeliveryMessage';


const readyToDeliverStatuses = ['Review Completed', 'Delivery Failed', 'Pending Review', 'Review In Progress'];
const enabledStatuses = ['Review Completed', 'Delivery Failed'];
const moveBackStatuses = ['Queued for Delivery', 'File Delivered', 'Delivery Failed'];

function MetadataSection({isVerified, isCarrierError, dataloadId, fileName, categories, sources, matchFileDetailsError}) {
  const {carrierId, matchJobId} = useParams();
  const [searchParams] = useSearchParams();

  const breadcrumbLinks = [{
    'title': 'All Match Results',
    'link': `/${carrierId}/all-match-results?${searchParams.has('allMatchResultsTab') ? 'tab=' + searchParams.get('allMatchResultsTab') + '&' : ''}${searchParams.get('allMatchResultsFilter') ? 'filter=' + searchParams.get('allMatchResultsFilter') : ''}`
  }];

  const {isError: matchFileStatusError, ...matchFileStatus} = useQuery(['getMatchJobStatus', carrierId, matchJobId], () => getMatchJobStatus(carrierId, matchJobId), {retry: false});

  const submitUpdateMatchJobStatus = useMutation({
    mutationFn: () => updateMatchJobStatus(carrierId, matchJobId, {fileStatus: 'underReview'}),
    onSuccess: () => matchFileStatus.refetch()
  });

  const toDoRecordsCount = matchFileStatus?.data?.todo;
  const matchRecordsCount = matchFileStatus?.data?.accepted;
  const noMatchRecordsCount = matchFileStatus?.data?.rejected;
  const totalMatchRecordsCount = matchFileStatus?.data?.total;
  const fileStatus = matchFileStatus?.data?.status;

  const fileStatusLabel = statusMap[fileStatus]; // status label like "File Delivered"
  const showQueuedAlert = searchParams.get('fileDeliveryQueued') === 'success';
  const showFileDeliveryButton = readyToDeliverStatuses?.includes(fileStatusLabel) || false;
  const fileDeliveryButtonEnabled = enabledStatuses.includes(fileStatusLabel) || false;
  const showMoveFileBackButton = moveBackStatuses.includes(fileStatusLabel) || false;
  const showMoveFileBackAlert = submitUpdateMatchJobStatus.isSuccess || false;

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h5" sx={{mt: 2, mb: 0}}>Match Results File Details</Typography>
          <Breadcrumb breadcrumbLinks={breadcrumbLinks} breadcrumb={'All Match Results'} currentPage=" Match Results File Details" />
        </Grid>
        <Grid item xs={6} sx={{textAlign: 'right'}}>
          {showMoveFileBackButton && isVerified && !showFileDeliveryButton &&
            <Button onClick={submitUpdateMatchJobStatus.mutate} variant="outlined" sx={{mt: '9px'}}>Move Back to Under Review</Button>
          }
          {showFileDeliveryButton && isVerified &&
            <>
              <Button disabled={!fileDeliveryButtonEnabled} component={Link} to={`/${carrierId}/all-match-results/${matchJobId}/deliver-file`} sx={{mt: '9px', ml: 1}} variant="contained" color="primary">Proceed to File Delivery</Button>
              <Typography sx={{display: 'block', mt: 2}} variant="caption" color="text.secondary">Queue file for delivery once record review is completed.</Typography>
            </>
          }
        </Grid>
        {showQueuedAlert && !showMoveFileBackAlert && <MatchFileDeliveryMessage dataLoadId={dataloadId} carrierId={carrierId}/>}
        {showMoveFileBackAlert &&
          <Grid item xs={12}>
            <Alert action={
              <Button
                color="success"
                size="small"
                LinkComponent={Link}
                to={`/${carrierId}/all-match-results?tab=underReview`}>
                VIEW FILE IN UNDER REVIEW
              </Button>
            } sx={{mb: 2}} severity="success">
              File {fileName} has been moved from 'Queued for Delivery' back to 'Under Review' and is ready for re-evaluation.
            </Alert>
          </Grid>
        }
        {submitUpdateMatchJobStatus.isError &&
          <Grid item xs={12}>
            <Alert severity="error" sx={{mb: 2}}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
          </Grid>
        }
      </Grid>
      <TopCards fileName={fileName} dataloadId={dataloadId} categories={categories} sources={sources} isVerified={isVerified} fileStatus={fileStatus} totalMatchRecordsCount={totalMatchRecordsCount} toDoRecordsCount={toDoRecordsCount} matchRecordsCount={matchRecordsCount} noMatchRecordsCount={noMatchRecordsCount} matchFileDetailsError={matchFileDetailsError} matchFileStatusError={matchFileStatusError} isCarrierError={isCarrierError}/>
    </>
  );
}

MetadataSection.propTypes = {
  fileName: PropTypes.string.isRequired,
  dataloadId: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  sources: PropTypes.array.isRequired,
  isVerified: PropTypes.bool.isRequired,
  matchFileDetailsError: PropTypes.bool,
  isCarrierError: PropTypes.bool
};

export default MetadataSection;
