import PropTypes from 'prop-types';
import {Button} from '@mui/material';
import {MailRounded} from '@mui/icons-material';


function EmailSupportButton({subject = '', body = '', ...props}) {
  return (
    <Button {...props} href={`mailto:support@evadata.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`} startIcon={<MailRounded />} variant='outlined' color='primary'>Email Support</Button>
  );
}

EmailSupportButton.propTypes = {
  subject: PropTypes.string,
  body: PropTypes.string
};

export default EmailSupportButton;
