import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {ArrowBackRounded} from '@mui/icons-material';
import {Button} from '@mui/material';

import EmailSupportButton from 'src/components/EmailSupportButton';
import BackToSignIn from './backToSignIn';
import RefreshButton from './refreshButton';


function ActionButtons({error, variant}) {
  const location = useLocation();
  const emailSubject = 'LENS Application error';

  if (variant === 'appError') {
    const _error = error || 'Unidentified App Error';
    const emailBody = `${_error}\n\nOccured At Path: ${location.pathname}`;

    return <><EmailSupportButton subject={emailSubject} body={emailBody} sx={{mr: '10px'}}/><RefreshButton/></>;
  }

  if (variant === '401') {
    const {name, errorSummary} = error;
    const emailBody = name && errorSummary ? `${name}: ${errorSummary}` : 'Unauthorized Error';

    return <><EmailSupportButton subject={emailSubject} body={emailBody} sx={{mr: '10px'}}/><BackToSignIn/></>;
  }

  if (variant === '404') {
    return <Button href="/" variant="contained" color="primary" startIcon={<ArrowBackRounded/>}>Back Home</Button>;
  }
}

ActionButtons.propTypes = {
  error: PropTypes.object,
  variant: PropTypes.string
};

export default ActionButtons;
