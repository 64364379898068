import {Route, Routes} from 'react-router-dom';
import {LoginCallback} from '@okta/okta-react';

import {RequiredAuth} from './RequiredAuth';
import CustomerAccounts from 'src/pages/Home';
import LoadingIcon from 'src/components/InitialLoader/loadingIcon';
import Logout from 'src/pages/Logout';
import AllMatchResults from 'src/pages/AllMatchResults';
import MatchRecordsOverview from 'src/pages/MatchResultsFileDetails';
import CustomerRecordDetails from 'src/pages/CustomerRecordDetails';
import LookUpId from 'src/pages/LookUpId';
import Automations from 'src/pages/Automations';
import AddAutomations from 'src/pages/Automations/AddEditAutomation/AddAutomation';
import ExecuteAutomations from 'src/pages/Automations/ExecuteAutomation';
import EditAutomations from 'src/pages/Automations/AddEditAutomation/EditAutomation';
import EvadatasMatchCategories from 'src/pages/EvadatasMatchCategories';
import DeliverMatchFile from 'src/pages/DeliverMatchFile';
import {CarrierContext} from 'src/contexts';
import {CarrierLayout, EvadataLayout} from 'src/components/Layout';
import MatchJobContext from 'src/contexts/matchJobContext';
import ErrorScreen from 'src/components/ErrorScreen';
import UnauthorizedErrorScreen from './unauthorizedErrorScreen';


const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<RequiredAuth />}>
        <Route element={<EvadataLayout/>}>
          <Route path='/' element={<CustomerAccounts />} />
        </Route>
        <Route path=':carrierId' element={<CarrierContext/>}>
          <Route element={<CarrierLayout/>}>
            <Route path='all-match-results' element={<AllMatchResults />} />
            <Route path='all-match-results/:matchJobId' element={<MatchJobContext/>}>
              <Route path='deliver-file' element={<DeliverMatchFile />} />
              <Route path='match-results-file-details' element={<MatchRecordsOverview />} />
              <Route path='match-results-file-details/:section/:customerId/customer-record-details' element={<CustomerRecordDetails />} />
            </Route>
            <Route path='automations' element={<Automations />} />
            <Route path='automations/add' element={<AddAutomations />} />
            <Route path='automations/execute' element={<ExecuteAutomations />} />
            <Route path='automations/:automationId' element={<EditAutomations />} />

            <Route path='look-up-id' element={<LookUpId />} />
            <Route path='evadatas-match-categories' element={<EvadatasMatchCategories />}/>
          </Route>
        </Route>
      </Route>
      <Route path="/logout" element={<Logout/>} />
      <Route path="/login/callback" element={<LoginCallback loadingElement={<LoadingIcon/>} errorComponent={UnauthorizedErrorScreen}/>} />
      <Route path='*' element={<ErrorScreen variant='404'/>} />
      <Route path='500' element={<ErrorScreen/>} />
    </Routes>
  );
};

export default AppRoutes;
