import React from 'react';
import PropTypes from 'prop-types';
import {Grid, FormControl, InputLabel, MenuItem, Select} from '@mui/material';

import LoadingButtonWithToolTip from 'src/components/LoadingButtonWithToolTip';


function ActionBarTools({handleFilterChange, isLoading, isDisabled, refresh, value}) {
  return (
    <Grid item sx={{px: 2, pt: 0.5}}>
      <LoadingButtonWithToolTip loading={isLoading} refresh={refresh}/>
      <FormControl>
        <InputLabel id="filter-select-helper-label">Filter by Status</InputLabel>
        <Select
          sx={{minWidth: '200px'}}
          select
          menuprops={{disableScrollLock: true}}
          value={value}
          onChange={handleFilterChange}
          label="Filter by Status"
          defaultValue="all"
          disabled={isDisabled}
        >
          <MenuItem value="all">All Statuses</MenuItem>
          <MenuItem value="processing">Running</MenuItem>
          <MenuItem value="completed">Run Completed</MenuItem>
          <MenuItem value="failed">Run Failed</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
}

ActionBarTools.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default ActionBarTools;
