import React, {useEffect, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {Paper, Typography} from '@mui/material';
import {useQuery} from 'react-query';
import PropTypes from 'prop-types';

import {getMatches} from 'src/api';
import TableWithPagination from 'src/components/TableWithPagination';
import {SkeletonRow} from './allMatchResultsSkeleton';
import matchesColumns from './matchesColumns';


const mapColumnVisibility = (showMatchDecisionView) => {
  return {
    new: {
      carrierId: true,
      updateStatus: false,
      sourcesMatched: true,
      categories: true,
      matchDecision: showMatchDecisionView,
      reviewDate: showMatchDecisionView,
      reviewers: showMatchDecisionView,
      firstName: !showMatchDecisionView,
      lastName: !showMatchDecisionView,
      city: !showMatchDecisionView,
      state: !showMatchDecisionView,
      dob: !showMatchDecisionView,
      emId: false,
      source: false
    },
    updated: {
      carrierId: true,
      updateStatus: true,
      sourcesMatched: true,
      categories: true,
      matchDecision: showMatchDecisionView,
      reviewDate: showMatchDecisionView,
      reviewers: showMatchDecisionView,
      firstName: !showMatchDecisionView,
      lastName: !showMatchDecisionView,
      city: !showMatchDecisionView,
      state: !showMatchDecisionView,
      dob: !showMatchDecisionView,
      emId: false,
      source: false
    },
    deleted: {
      carrierId: false,
      updateStatus: false,
      sources: false,
      categories: false,
      matchDecision: false,
      reviewDate: false,
      reviewers: false,
      firstName: true,
      lastName: true,
      city: true,
      state: true,
      dob: true,
      matchId: true,
      source: true
    }
  };
};

function MatchResultsFileDetailsTable({isVerified, isCarrierError}) {
  const {carrierId, matchJobId} = useParams();
  const [searchParams] = useSearchParams();
  const [params, setParams] = useState({
    limit: 10,
    next: '',
    previous: ''
  });
  const [rowCount, setRowCount] = useState({
    rowStart: 1,
    rowEnd: params.limit
  });
  const showMatchDecisionView = searchParams.get('showMatchDecisionView') === 'true';
  const selectedSection = searchParams.get('section') || 'new';
  const selectedMatchDecision = searchParams.get('matchDecision');
  const selectedMatchType = searchParams.get('matchType');
  const selectedSources = searchParams.getAll('sources') || [];
  const selectedCategories = searchParams.getAll('categories') || [];

  const matchResultsDetailsData = useQuery(['getMatches', params, matchJobId, selectedMatchDecision, selectedMatchType, selectedSources, selectedCategories, selectedSection], () => getMatches(carrierId, matchJobId, selectedSection, params.limit, params.previous, params.next, selectedMatchDecision, selectedMatchType, selectedSources, selectedCategories), {retry: false});

  useEffect(() => {
    if (!matchResultsDetailsData?.isLoading) {
      setRowCount((previousRowCount) => {
        return {
          rowStart: previousRowCount.rowStart,
          rowEnd: previousRowCount.rowStart + matchResultsDetailsData?.data?.results?.length - 1
        };
      });
    }
  }, [matchResultsDetailsData?.data, matchResultsDetailsData?.isLoading]);

  const nextPage = function() {
    setParams((previousParams) => {
      return {
        limit: previousParams.limit,
        next: matchResultsDetailsData?.data?.pagination?.next,
        previous: ''
      };
    });

    setRowCount((previousRowCount) => {
      return {
        rowStart: previousRowCount.rowStart + params.limit
      };
    });
  };

  const previousPage = function() {
    setParams((previousParams) => {
      return {
        limit: previousParams.limit,
        next: '',
        previous: matchResultsDetailsData?.data?.pagination?.previous
      };
    });

    setRowCount((previousRowCount) => {
      return {
        rowStart: previousRowCount.rowStart - params.limit
      };
    });
  };

  const setPageSize = (pageSize) => {
    setParams({
      limit: pageSize,
      next: '',
      previous: ''
    });

    setRowCount({
      rowStart: 1,
      rowEnd: pageSize
    });
  };

  const emptyMessage = function() {
    if (selectedMatchDecision !== '' || selectedMatchType !== '' || selectedCategories?.length > 0 || selectedSources?.length > 0) {
      return 'No customer records found for the selected criteria.';
    }
    return 'No customer records to display.';
  }();

  return (
    <>
      <Paper sx={{mb: 2, mt: 1}}>
        <TableWithPagination
          columnVisibilityModel={mapColumnVisibility(showMatchDecisionView)[selectedSection]}
          rows={matchResultsDetailsData?.data?.results}
          columns={matchesColumns(selectedSection, searchParams)}
          isDataTableLoading={matchResultsDetailsData?.isLoading}
          rowHeight='auto'
          next={matchResultsDetailsData?.data?.pagination?.next}
          prev={matchResultsDetailsData?.data?.pagination?.previous}
          isApiError={isCarrierError || matchResultsDetailsData?.isError}
          SkeletonRow={<SkeletonRow selectedSection={selectedSection} isVerified={isVerified}/>}
          emptyMessage={emptyMessage}
          setPageSize={setPageSize}
          getNextPage={nextPage}
          getPreviousPage={previousPage}
          pageSize={params?.limit}
          rowStart={rowCount.rowStart}
          rowEnd={rowCount.rowEnd}
        />
      </Paper>
      {selectedSection === 'updated' && <Typography variant="body2">NOTE: Update match types have no match status shown because the customer ID had previous death record matches where that match decision was already made.</Typography>}
    </>
  );
}

MatchResultsFileDetailsTable.propTypes = {
  isVerified: PropTypes.bool.isRequired,
  isCarrierError: PropTypes.bool
};

export default MatchResultsFileDetailsTable;
