import PropTypes from 'prop-types';

import ErrorScreen from 'src/components/ErrorScreen';


const UnauthorizedErrorScreen = ({error}) => <ErrorScreen error={error} variant="401"/>;

UnauthorizedErrorScreen.propTypes = {
  error: PropTypes.object
};

export default UnauthorizedErrorScreen;
