import PropTypes from 'prop-types';
import {Grid} from '@mui/material';
import {memo} from 'react';

import CustomerRecordCard from './customerRecordCard';
import SourcesCategoriesCard from './sourcesCategoriesCard';


const TopCards = memo(function TopCards({carrierId, customerRecord, matches, isCustomerRecordError, isMatchJobError, isCarrierError, configCategories, configSources}) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <CustomerRecordCard carrierId={carrierId} customerRecord={customerRecord} isError={isCustomerRecordError || isCarrierError} />
      </Grid>
      <Grid item xs={6}>
        <SourcesCategoriesCard matches={matches} configCategories={configCategories} configSources={configSources} isError={isMatchJobError || isCarrierError} />
      </Grid>
    </Grid>
  );
});

TopCards.propTypes = {
  carrierId: PropTypes.string,
  customerRecord: PropTypes.object,
  matches: PropTypes.array,
  isCustomerRecordError: PropTypes.bool,
  isMatchJobError: PropTypes.bool,
  isCarrierError: PropTypes.bool,
  configCategories: PropTypes.array,
  configSources: PropTypes.array
};

export default TopCards;


