import React from 'react';
import {useOutletContext, Link} from 'react-router-dom';
import {Typography, Skeleton} from '@mui/material';

import LoadError from 'src/components/LoadError';


const CompanyName = function() {
  const {carrier} = useOutletContext();

  if (carrier.isLoading) {
    return <Skeleton width='394px' height='32px' variant='rectangular' sx={{justifySelf: 'flex-end'}}/>;
  }

  if (carrier.isError || !carrier?.name) {
    return <LoadError sx={{mt: -1}} variant='inline' message='Account failed to load, please try again.'/>;
  }

  return (
    <Link to='/'>
      <Typography varaint='subtitle1' sx={{fontWeight: 700}}>
        {carrier?.name}
      </Typography>
    </Link>
  );
};

export default CompanyName;
