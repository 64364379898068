import React from 'react';
import PropTypes from 'prop-types';
import {Link, useParams, useSearchParams} from 'react-router-dom';
import {Button, Grid, Typography} from '@mui/material';
import {ArrowBack} from '@mui/icons-material';


function Header({headerText}) {
  const {carrierId} = useParams();

  const [searchParams] = useSearchParams();
  const urlSearchParams = searchParams.toString();

  return (
    <Grid container sx={{mt: 3, mb: 5}}>
      <Grid item xs={6}>
        <Typography variant="h5">{headerText}</Typography>
      </Grid>
      <Grid item xs={6} sx={{textAlign: 'right'}}>
        <Button LinkComponent={Link} to={`/${carrierId}/automations?${urlSearchParams}`} variant="outlined" startIcon={<ArrowBack />}>Back to Automations</Button>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  headerText: PropTypes.string.isRequired
};

export default Header;
