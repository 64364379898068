import React, {useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import UpdateButtons from './updateButtons';
import {PageWrapper} from './styles';
import Header from './header';
import AlertWithClose from './alertWithClose';
import {DisplayAutomationsTab, DisplayExecuteAutomationsTab} from './AutomationTabs';


function Automations() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [disableHeaderButtons, setDisableHeaderButtons] = useState(true);

  const tab = searchParams.get('tab');

  const clearCompletedAlert = () => {
    searchParams.delete('completed');
    setSearchParams(searchParams);
  };

  return (
    <PageWrapper>
      <Header disable={disableHeaderButtons}/>
      {searchParams.get('completed') && <AlertWithClose clearCompletedAlert={clearCompletedAlert} automationName={searchParams.get('name')}/>}
      <UpdateButtons currentAutomationTab={tab}/>
      {(tab === 'all' || tab === null) && <DisplayAutomationsTab setDisableHeaderButtons={setDisableHeaderButtons} />}
      {tab === 'by-match-file' && <DisplayExecuteAutomationsTab setDisableHeaderButtons={setDisableHeaderButtons}/>}
    </PageWrapper>
  );
}

export default Automations;
