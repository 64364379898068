import React, {useState} from 'react';

import {Alert, Box, Grid, Paper, Typography} from '@mui/material';
import {useSearchParams, useParams, useOutletContext} from 'react-router-dom';

import ActionBar from './actionBar';
import AllMatchResultsTable from './AllMatchResultsTable';

const drawerWidth = 400;

function MatchResults() {
  const [searchParams, setSearchParams] = useSearchParams();
  const {carrierId} = useParams();
  const [isDownloadError, setIsDownloadError] = useState(false);
  const [matchResultsGroup, setMatchResultsGroup] = useState(searchParams.get('tab') || 'processing');
  const [statusFilter, setStatusFilter] = useState(searchParams.get('filter') || 'all');

  const [savedParams, setSavedParams] = useState({
    processing: {
      status: statusFilter,
      fileStatus: 'processing'
    },
    prescan: {
      fileStatus: 'prescan'
    },
    underReview: {
      status: statusFilter,
      fileStatus: 'underReview'
    },
    queuedForDelivery: {
      fileStatus: 'queuedForDelivery'
    },
    delivered: {
      status: statusFilter,
      fileStatus: 'delivered'
    }
  });

  const {carrier} = useOutletContext();

  const verified = carrier.isVerified;

  function clearDownloadError() {
    setIsDownloadError(false);
  }

  const selectMatchResultsGroup = (selection) => {
    const {status} = savedParams[selection];

    if (status) {
      setSearchParams({tab: selection, filter: status});
    } else {
      setSearchParams({tab: selection});
    }

    setMatchResultsGroup(selection);
    setStatusFilter(savedParams?.[selection]?.status || 'all');
  };

  const handleFilterChange = (event) => {
    const {target: {value: selectedStatusFilter}} = event;

    setStatusFilter(selectedStatusFilter);
    setSavedParams({...savedParams, [matchResultsGroup]: {status: selectedStatusFilter}});

    const _searchParams = Object.fromEntries(searchParams);

    setSearchParams({..._searchParams, filter: selectedStatusFilter});
  };

  return (
    <Box sx={{display: 'flex'}}>
      <Box
        component="main"
        sx={{flexGrow: 1, width: {sm: '100%', md: `calc(100% - ${drawerWidth}px)`}}}
      >
        <Typography variant="h5" color="text.primary" sx={{ml: 0, mb: 2}}>All Match Results</Typography>

        {isDownloadError && <Alert sx={{mb: 2, mr: 3}} onClick={clearDownloadError} severity="error">An error was encountered when retrieving the file, try downloading it again.</Alert>}

        <ActionBar matchResultsGroup={matchResultsGroup} selectMatchResultsGroup={selectMatchResultsGroup} statusFilter={statusFilter} handleFilterChange={handleFilterChange} verified={verified} isCarrierError={carrier?.isError}/>

        <Grid container spacing={3} >
          <Grid item xs={12}>
            <Paper sx={{mb: verified ? 0 : 2, mt: 1}}>
              <AllMatchResultsTable
                carrierId={carrierId}
                setIsDownloadError={setIsDownloadError}
                fileStatus={matchResultsGroup}
                status={statusFilter}
                verified={verified}
                isCarrierError={carrier?.isError}
              />
            </Paper>
          </Grid>
          {verified && (matchResultsGroup === 'queuedForDelivery' || matchResultsGroup === 'delivered') && <Grid item xs={12}>
            <Typography variant="body2">* File delivered includes all relevant matched records, which may consist of ‘Net New’, ‘Updated’ and ‘Deleted’ matches.
            </Typography>
            <Typography variant="body2"> ** ‘No Match’ records are not included in the delivered file.</Typography>
          </Grid>}
        </Grid>
      </Box>
    </Box>
  );
}

export default MatchResults;
