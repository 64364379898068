import React from 'react';
import PropTypes from 'prop-types';
import {Link, useSearchParams, useParams} from 'react-router-dom';
import {Box, Button, Grid, Typography} from '@mui/material';


function FormFooter({matchFiles, isLoading}) {
  const {carrierId} = useParams();

  const [searchParams] = useSearchParams();
  const urlSearchParams = searchParams.toString();

  return (
    <Box sx={{p: 4, pt: 2, pb: 3}}>
      <Grid container>
        {!(matchFiles?.length === 0 || isLoading) &&
          <Grid item xs={12} sx={{textAlign: 'center'}}>
            <Typography mb={1}>Automations will run against death record matches still in 'To Do', not on 'Match' or 'No Match' decisions.</Typography>
          </Grid>
        }
        <Grid item xs={6}>
          <Button LinkComponent={Link} to={`/${carrierId}/automations?${urlSearchParams}`} variant="outlined" color="primary" >Cancel</Button>
        </Grid>
        <Grid item xs={6} sx={{textAlign: 'right'}}>
          <Button type="submit" variant="contained" color="primary" disabled={matchFiles?.length === 0 || isLoading}>Run Automation(s)</Button>
        </Grid>
      </Grid>
    </Box>
  );
}

FormFooter.propTypes = {
  matchFiles: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default FormFooter;
