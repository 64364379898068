import PropTypes from 'prop-types';
import {Box, Grid, Skeleton, Typography, styled} from '@mui/material';

import BackBar from './backBar';
import FileStatusChip from 'src/components/FileStatusChip';
import LoadError from 'src/components/LoadError';


const MetadataBarWrapper = styled(Box)(
  ({theme}) => `
    .meta-bar-container {
        z-index: 999;
        left: 0;
        top: 62px;
        position: fixed;
        align-content: center;
        background-color: ${theme.sidebar.background};
    }
  `
);

const errorMessage = 'Failed to load, please try again.';

function MetadataBar({carrierId, matchJobId, section, fileName, fileStatus, dataloadId, showBackBar, isApiError}) {
  return (
    <>
      <MetadataBarWrapper>
        <Grid className='meta-bar-container' container height="44px" pl={1} pr={1}>
          <Grid item xs={isApiError ? 8 : 9} alignContent="center">
            <Typography variant="subtitle2" color="text.primary" display="inline">File Name: <Typography variant="body2" display="inline">{isApiError ? <LoadError variant='inline' message={errorMessage}/> : fileName || <Skeleton sx={{display: 'inline-block'}} width={300} height={20} />}</Typography></Typography>
            <Typography variant="subtitle2" color="text.primary" display="inline" ml={1}>Dataload ID: <Typography variant="body2" display="inline">{isApiError ? <LoadError variant='inline' message={errorMessage}/> : dataloadId || <Skeleton sx={{display: 'inline-block'}} width={300} height={20} />}</Typography></Typography>
          </Grid>
          <Grid item xs={isApiError ? 4 : 3}>
            <Box display="flex" justifyContent="flex-end">
              <Typography variant="subtitle2" color="text.primary" display="inline" mr={1}>File Status: {isApiError && <LoadError variant='inline' message={errorMessage}/>}</Typography>
              {!isApiError && <FileStatusChip fileStatus={fileStatus} isDataLoading={!fileStatus} size="small"/>}
            </Box>
          </Grid>
        </Grid>
      </MetadataBarWrapper>
      {showBackBar &&
        <BackBar carrierId={carrierId} matchJobId={matchJobId} section={section} />
      }
    </>
  );
}

MetadataBar.propTypes = {
  carrierId: PropTypes.string,
  matchJobId: PropTypes.string,
  section: PropTypes.string,
  fileName: PropTypes.string,
  fileStatus: PropTypes.string,
  dataloadId: PropTypes.string,
  showBackBar: PropTypes.bool,
  isApiError: PropTypes.bool
};

export default MetadataBar;
