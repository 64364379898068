import React, {useState} from 'react';

import {DownloadingRounded, ErrorOutline} from '@mui/icons-material';
import fileDownload from 'js-file-download';

import {getErrorFileSignedUrl} from 'src/api';
import {styled} from '@mui/system';
import {Box, Button} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

const CustomTooltip = styled(({className, ...props}) => (
  <Tooltip placement="right" arrow {...props} componentsProps={{tooltip: {className: className}}} />
))(() => (`
    color: #D9000B;
    background-color: #fbe5e7;
    width: 110px;
    border-bottom: 2px solid #D9000B;
    .MuiTooltip-arrow { 
      color: #fbe5e7;
    }
`));

const DownloadsWrapper = styled(Box)(
  ({theme}) => `
    display: inline-flex;
    .error{
      border: 2px solid ${theme.colors.error.main};
      z-index: 1
    }
    :hover {
      background: transparent;
    }
    .MuiSvgIcon-root {
      display: inline-flex;
      margin-top: 20px;
    }
    MuiTooltip-tooltip.downloads-tooltip {
      color: ${theme.colors.error.main} !important;
      background-color: red !important;
      display: inline-flex;
      margin-top: 20px;
    }
  `
);

function ErrorFileDownload({carrierId, dataLoadId, setIsDownloadError}) {
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [hasError, setHasError] = useState(false);

  function handleErrors() {
    setDownloadInProgress(false);
    setHasError(true);
    setIsDownloadError(true);
  }

  async function handleDownload() {
    try {
      setDownloadInProgress(true);
      setHasError(false);
      const {url, filename} = await getErrorFileSignedUrl(carrierId, dataLoadId);
      const result = await fetch(url);
      if (!filename || !url) {
        setHasError(true);
      }
      const data = await result.blob();
      fileDownload(data, filename);
      setDownloadInProgress(false);
      setIsDownloadError(false);
    } catch (error) {
      handleErrors();
    }
  }

  return <DownloadsWrapper>
    <div className="wrap-cell">
      <Button disabled={downloadInProgress} className="error-download-btn" variant="outlined" size="small" onClick={handleDownload} sx={{minWidth: '180px'}}>
        {downloadInProgress && <DownloadingRounded color="action.hover" className="downloading" sx={{height: '20px', ml: '-10px', mr: '5px', display: 'inline-block', mt: '0px !important'}} />}
        {downloadInProgress ? 'DOWNLOADING...' : 'DOWNLOAD ERRORS'}
      </Button></div>
    {hasError &&
      <CustomTooltip className="downloads-tooltip" title="Error retrieving file, try again.">
        <ErrorOutline color="error" sx={{ml: 2, textAlign: 'right'}} />
      </CustomTooltip>}
  </DownloadsWrapper>;
}

ErrorFileDownload.propTypes = {
  carrierId: PropTypes.string,
  dataLoadId: PropTypes.string,
  setIsDownloadError: PropTypes.func
};

export default ErrorFileDownload;

