import {useOktaAuth} from '@okta/okta-react';
import {Button} from '@mui/material';
import {ArrowBack} from '@mui/icons-material';


function BackToSignIn() {
  const {oktaAuth} = useOktaAuth();
  const handleLogout = async () => oktaAuth.signOut();

  return (
    <Button onClick={handleLogout} variant="contained" color="primary" startIcon={<ArrowBack/>}>Back to Sign In</Button>
  );
}

export default BackToSignIn;
