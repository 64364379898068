import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useQuery} from 'react-query';

import getAllMatchesColumns from './matchesColumns';
import getColumnVisibilityModelMap from './columnVisibilityModel';
import TableWithPagination from 'src/components/TableWithPagination';
import SkeletonRow from './allMatchResultsSkeleton';
import {getMatchResults} from 'src/api';


const AllMatchResultsTable = function({carrierId, fileStatus, status, verified, setIsDownloadError, isCarrierError}) {
  const [paginationCache, setPaginationCache] = useState(new Map());
  const [rowCountCache, setRowCountCache] = useState(new Map());

  const paginationCacheKey = useCallback(() => {
    if (status) {
      return `${fileStatus}-${status}`;
    }

    return fileStatus;
  }, [status, fileStatus]);

  const getAllMatchResultsPaginationParams = useCallback(() => {
    const defaultParams = {
      limit: 10,
      next: '',
      previous: ''
    };

    const key = paginationCacheKey();

    const current = paginationCache.get(key);

    const payload = {...defaultParams, ...current};

    return payload;
  }, [paginationCacheKey, paginationCache]);

  const setAllMatchResultsPaginationParams = function(incomingPagination) {
    setPaginationCache((previousCache) => {
      const newCache = new Map(previousCache);

      const key = paginationCacheKey();
      const previousPagination = newCache.get(key);
      const pagination = {...previousPagination, ...incomingPagination};

      newCache.set(key, pagination);

      return newCache;
    });
  };

  const handleRowCountCache = useCallback((rowCount) => {
    setRowCountCache((previousCache) => {
      const cache = new Map(previousCache);

      const key = paginationCacheKey();
      const previousRowCountCache = cache.get(key);
      const rowCountCache = {...previousRowCountCache, ...rowCount};

      cache.set(key, rowCountCache);

      return cache;
    });
  }, [paginationCacheKey]);

  const getRowCount = useCallback(() => {
    const {limit} = getAllMatchResultsPaginationParams();
    const defaultRow = {
      rowStart: 1,
      rowEnd: limit
    };

    const key = paginationCacheKey();

    const current = rowCountCache.get(key);
    const rowCount = {...defaultRow, ...current};

    return rowCount;
  }, [rowCountCache, paginationCacheKey, getAllMatchResultsPaginationParams]);

  const getNextPage = () => {
    setAllMatchResultsPaginationParams({
      next: data?.pagination?.next,
      previous: ''
    });

    const {rowStart} = getRowCount();
    const {limit} = getAllMatchResultsPaginationParams();

    handleRowCountCache({rowStart: rowStart + limit});
  };

  const getPreviousPage = () => {
    setAllMatchResultsPaginationParams({
      next: '',
      previous: data?.pagination?.previous
    });

    const {rowStart} = getRowCount();
    const {limit} = getAllMatchResultsPaginationParams();

    handleRowCountCache({rowStart: rowStart - limit});
  };

  const setPageSize = function(pageSize) {
    /**
     * Reset next and previous keys when there is a new page size.
     * CAUTION: Since the page size, next and previous keys are tied to fileStatus and Staus,
     * changing the pageSize only affects the unique combination of fileStatus and Status. All others
     * pageSizes, next and previous keys stay unchanged. This applies to the pagination row counts at
     * the bottom of the table as well.
     */
    setAllMatchResultsPaginationParams({
      limit: pageSize,
      next: '',
      previous: ''
    });

    handleRowCountCache({
      rowStart: 1,
      rowEnd: pageSize
    });
  };

  const statusParam = status === 'all' ? undefined : status;
  const paginationParams = getAllMatchResultsPaginationParams();

  const {isLoading, isError, data} = useQuery(['allMatchResults', paginationParams, fileStatus, statusParam, carrierId], () => getMatchResults(carrierId, fileStatus, paginationParams.limit, paginationParams.previous, paginationParams.next, statusParam), {retry: false});

  useEffect(() => {
    if (!isLoading) {
      const {rowStart} = getRowCount();

      handleRowCountCache({rowEnd: rowStart + data?.results?.length - 1});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, handleRowCountCache]);

  const {rowEnd, rowStart} = getRowCount();

  return (
    <TableWithPagination
      columnVisibilityModel={getColumnVisibilityModelMap(verified)[fileStatus]}
      rows={data?.results}
      columns={getAllMatchesColumns(setIsDownloadError, carrierId, verified, fileStatus, status)}
      isDataTableLoading={isLoading}
      rowHeight={60}
      pageSize={paginationParams?.limit || 10}
      next={data?.pagination?.next}
      prev={data?.pagination?.previous}
      getNextPage={getNextPage}
      getPreviousPage={getPreviousPage}
      setPageSize={setPageSize}
      isApiError={isError || isCarrierError}
      SkeletonRow={<SkeletonRow fileStatus={fileStatus} isVerified={verified} />}
      emptyMessage='No files to display.'
      rowEnd={rowEnd}
      rowStart={rowStart}
    />
  );
};

AllMatchResultsTable.propTypes = {
  carrierId: PropTypes.string,
  fileStatus: PropTypes.string,
  status: PropTypes.string,
  verified: PropTypes.bool,
  setIsDownloadError: PropTypes.func,
  isCarrierError: PropTypes.bool
};

export default AllMatchResultsTable;
