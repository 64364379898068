import PropTypes from 'prop-types';
import {Typography, Grid, ThemeProvider, CssBaseline} from '@mui/material';

import {ReactComponent as EvadataLogo} from 'src/images/EvadataLogo404.svg';
import UnauthorizedGraphic from 'src/images/401UnauthorizedGraphic.png';
import NotFoundGraphic from 'src/images/404PageSearchGraphic.png';
import AppErrorGraphic from 'src/images/500ServerCommGraphic.png';
import {EvadataTheme} from 'src/theme/schemes/EvadataTheme';
import ActionButtons from './ActionButtons';


const icons = {
  401: UnauthorizedGraphic,
  404: NotFoundGraphic,
  appError: AppErrorGraphic
};

const headings = {
  401: 'No authentication found.',
  404: 'The page can\'t be found',
  appError: 'Something went wrong.'
};

const messages = {
  401: 'Sorry but we couldn’t validate your credentials. Please try signing in again or contact Evadata if the issue persists.',
  404: 'Sorry but the page you are looking for does not exist, has been moved, the name changed or is temporarily unavailable.',
  appError: 'There was a problem loading this page. Please try refreshing your browser or contact Evadata if the issue persists.'
};

function ErrorScreen({error, variant='appError'}) {
  return (
    <ThemeProvider theme={EvadataTheme}>
      <CssBaseline/>
      <Grid container>
        <Grid item xs={12} sx={{textAlign: 'center', marginTop: '100px'}}>
          <EvadataLogo />
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center', marginTop: '40px'}}>
          <img src={icons?.[variant]} alt={variant}/>
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center', marginTop: '20px'}}>
          <Typography variant="h2" color="#00396F">{headings?.[variant]}</Typography>
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center', marginTop: '50px'}}>
          <Typography variant="h5" color="black" sx={{maxWidth: '700px', margin: '0 auto'}}>{messages?.[variant]}</Typography>
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center', marginTop: '50px'}}>
          <ActionButtons error={error} variant={variant}/>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

ErrorScreen.propTypes = {
  error: PropTypes.object,
  variant: PropTypes.string
};

export default ErrorScreen;
