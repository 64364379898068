import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {Divider, Grid, styled, Box} from '@mui/material';

import BaseAppBar from '../../AppBar';
import CarrierName from '../../AppBar/CarrierName';
import HeaderUserbox from '../../AppBar/Userbox';
import EvadataLogo from '../../AppBar/EvadataLogo';


const LogoContainerStyled = styled(Grid)(({theme}) => (`
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px rgb(0 0 0 / 14%), 0px 1px 10px rgb(0 0 0 / 12%);
  z-index: 1100;
  position: fixed;
  background-color: ${theme.sidebar.background};
  height: 80px;
`));

function CarrierAppBar() {
  const {carrierId} = useParams();

  return (
    <BaseAppBar>
      <LogoContainerStyled container spacing={2} sx={{pl: 2, pr: 2}} justifyContent='space-between'>
        <Grid item sx={{pt: 3}}>
          <Link to={`/${carrierId}/all-match-results`}><EvadataLogo sx={{ml: 12}}/></Link>
        </Grid>
        <Grid item sx={{display: 'inline-flex'}}>
          <Box mt={2} mr={2}>
            <CarrierName/>
          </Box>
          <Divider orientation='vertical' variant='middle' sx={{mr: 1}}/>
          <Box mt='6px'>
            <HeaderUserbox />
          </Box>
        </Grid>
      </LogoContainerStyled>
    </BaseAppBar>
  );
}

export default CarrierAppBar;
