import PropTypes from 'prop-types';
import {Typography, styled, Button, Box} from '@mui/material';


const ErrorWrapper = styled(Box)(
  () => `

    padding-top: 10px;
    text-align: center;
    margin: 0 auto;
  `
);

function LoadError({variant='container', message='We\'re having trouble loading the content, please try again.', ...props}) {
  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <ErrorWrapper {...props} display={variant === 'inline' ? 'inline-flex' : ''}>
      <Typography variant="body1" color="text.secondary" sx={{mb: variant === 'container' ? 2 : 1, mr: variant === 'container' ? 0 : 2, fontSize: '15px'}}>{message}</Typography>
      <Button size="small" variant="contained" color="primary" onClick={refreshPage} sx={{mb: .5, mt: -.5}}>Retry</Button>
    </ErrorWrapper>
  );
}

LoadError.propTypes = {
  variant: PropTypes.string,
  message: PropTypes.string
};

export default LoadError;
